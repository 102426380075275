/*
 * @Author: hwu
 * @Date: 2020-12-28 20:30:54
 * @Description: socket连接
 * @LastEditTime: 2023-01-06 17:15:32
 */
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
// import store from '@/store'

let stompClient

/**
 * 创建socket链接
 * @param {String} cartId 购物车Id，socket的login
 * @param {String} userId 用户Id
 */
export const connectSocketApi = (cartId, userId) => {
  const headers = {
    cartId: cartId,
    userId: userId
  }
  const socket = new SockJS(`${process.env.VUE_APP_DOMIAN}/weihong-websocket`)
  stompClient = Stomp.over(socket)
  return new Promise((resolve, reject) => {
    stompClient.connect(
      headers,
      () => {
        resolve()
      },
      err => {
        reject(err)
      }
    )
  })
}

export const closeSocketApi = () => {
  if (stompClient != null) {
    stompClient.disconnect()
  }
}

/**
 * 订阅购物车数据变化
 * @param {Function} callback 数据变化后的回调方法
 */
export const subscribeCartApi = callback => {
  stompClient.subscribe('/weihong/topic/sync/cart', msg => {
    callback(JSON.parse(msg.body))
  })
}

/**
 * 订阅共同点餐人数变化
 * @param {Function} callback 数据变化后的回调方法
 */
export const subscribeOrderTogetherApi = callback => {
  stompClient.subscribe('/weihong/topic/sync/orderTogether', msg => {
    callback(JSON.parse(msg.body))
  })
}

/**
 * 订阅订单支付页数据变化
 * @param {Function} callback 数据变化后的回调方法
 */
export const subscribeOrderDetailApi = callback => {
  stompClient.subscribe('/weihong/topic/sync/orderDetail', msg => {
    callback(JSON.parse(msg.body))
  })
}

/**
 * 订阅订单完成事件
 * @param {Function} callback 数据变化后的回调方法
 */
export const subscribeOrderSuccessApi = callback => {
  stompClient.subscribe('/weihong/topic/sync/successOrder', msg => {
    callback(JSON.parse(msg.body))
  })
}

/**
 * 订阅客扫支付超时事件
 * @param {Function} callback 数据变化后的回调方法
 */
export const subscribeCustomScanPayOutOfTimeApi = callback => {
  stompClient.subscribe('/weihong/topic/sync/customScanPayOutOfTime', msg => {
    callback(msg.body)
  })
}

/**
 * 订阅客扫支付，pos机修改订单事件
 * @param {Function} callback 数据变化后的回调方法
 */
export const subscribePosUpdateOrderApi = callback => {
  stompClient.subscribe('/weihong/topic/sync/posUpdateOrder', msg => {
    callback(msg.body)
  })
}

/**
 * 订阅开始客扫支付事件
 * @param {Function} callback 数据变化后的回调方法
 */
export const subscribeStartCustomScanPayApi = callback => {
  stompClient.subscribe('/weihong/topic/sync/startCustomScanPay', msg => {
    callback(msg.body)
  })
}
// 订阅购物车数据变化
// const subscribeCart = () => {
//   stompClient.subscribe('/weihong/topic/sync/cart', msg => {
//     // msg.body存放的是服务端发送给我们的信息
//     const cartInfo = msg.body
//     // 更新vuex里面的购物车数据
//     store.dispatch('order/updateCartInfoVuex', JSON.parse(cartInfo))
//   })
// }

// // 订阅订单详情变化
// const subscribeOrderDetail = () => {
//   stompClient.subscribe('/weihong/topic/sync/orderDetail', msg => {
//     // msg.body存放的是服务端发送给我们的信息
//     console.log(msg.body)
//   })
// }

// // 订阅订单详情变化
// const subscribeOrderSuccess = () => {
//   stompClient.subscribe('/weihong/topic/sync/successOrder', msg => {
//     // msg.body存放的是服务端发送给我们的信息
//     console.log(msg.body)
//   })
// }

// 订阅订单详情变化
// const subscribeOrderTogether = () => {
//   stompClient.subscribe('/weihong/topic/sync/orderTogether', msg => {
//     // msg.body存放的是服务端发送给我们的信息
//     console.log(msg.body)
//   })
// }
